<script setup lang="ts">
import {
  ComponentBlockReferenceSlider,
  ComponentBlockReferenceSliderFragment,
  Enum_Componentblockreferenceslider_Variation,
  Enum_Componentblockreferenceslider_Flipped,
} from "~/types/graphql";
import Arrow from "@/assets/icons/arrow.svg?component";
import { Swiper, SwiperSlide, useSwiper } from "swiper/vue";
import { Controller } from "swiper";
import "swiper/css";

const props = defineProps<{
  ReferenceSliderPage?: ComponentBlockReferenceSliderFragment["ReferenceSliderPage"];
  ReferenceSliderItem: ComponentBlockReferenceSliderFragment["ReferenceSliderItem"];
  ReferenceSliderFlipped?: ComponentBlockReferenceSliderFragment["ReferenceSliderFlipped"];
  ReferenceSliderVariation?: ComponentBlockReferenceSliderFragment["ReferenceSliderVariation"];
}>();

const router = useRouter();
const { t } = useI18n({
  messages: {
    de: {
      readMore: "Zur Referenz",
    },
    en: {
      readMore: "Reference details",
    },
  },
});
// linkable parent page for blog posts
const page = ref(props.ReferenceSliderPage);
const parentLink = computed(() => {
  return page?.value?.data?.attributes?.Slug
    ? `/${page?.value?.data?.attributes?.Slug}`
    : "";
});

const swiperController: Ref<ReturnType<typeof useSwiper> | null> = ref(null);
const setControlledSwiper = (swiper: ReturnType<typeof useSwiper>) => {
  swiperController.value = swiper;
};

</script>

<template>
  <layout-block
    class="reference-slider"
    :class="{
      'bg-integer-black text-integer-white':
        ReferenceSliderVariation ===
        Enum_Componentblockreferenceslider_Variation.Dark,
      'bg-integer-white text-integer-black':
        ReferenceSliderVariation ===
        Enum_Componentblockreferenceslider_Variation.Light,
    }"
    :outer-space="false"
  >
    <div class="outer-space flex w-full xl:mb-12" v-if="parentLink">
      <nuxt-link
        :to="parentLink"
        class="flex whitespace-pre items-center text-s font-semibold w-fit"
        >{{ ReferenceSliderPage?.data?.attributes?.Name }} <arrow
      /></nuxt-link>
      <module-slider-controls
        v-if="swiperController && ReferenceSliderItem.length > 1"
        type="secondary"
        :variation="ReferenceSliderVariation"
        :swiperController="swiperController"
        class="w-fit ml-auto sm:flex xl:hidden"
      />
    </div>

    <swiper
      :modules="[Controller]"
      @swiper="setControlledSwiper"
      :cssMode="true"
      class="mt-5 xl:mt-0"
    >
      <module-slider-controls
        v-if="swiperController && ReferenceSliderItem.length > 1"
        :swiperController="swiperController"
        type="secondary"
        :variation="ReferenceSliderVariation"
        class="order-3 hidden xl:flex absolute top-0 right-0 px-0 xl:px-5.5"
      />
      <swiper-slide
        ref="referenceSlide"
        v-for="item in ReferenceSliderItem"
      >
        <div>
          <div class="grid md:grid-cols-2 xl:grid-cols-12">
            <div
              class="outer-space justify-self-start place-self-center order-2 md:order-1 text-h2 xl:max-w-xl mt-8 md:mt-0"
              :class="
                ReferenceSliderFlipped ===
                Enum_Componentblockreferenceslider_Flipped.Left
                  ? ' md:col-start-2 xl:col-start-7 xl:col-span-5 md:row-start-1'
                  : 'xl:col-start-2 xl:col-span-5 pr-5 xl:pr-0'
              "
            >
              <base-tag
                v-for="tag in item?.reference?.data?.attributes
                  ?.reference_categories?.data"
                type="primary"
                class="mr-2"
                :class="ReferenceSliderVariation ===
                  Enum_Componentblockreferenceslider_Variation.Dark
                    ? 'bg-light-black border-light-black text-integer-white'
                    : ''"
              >
                {{ tag.attributes?.display_name }}
              </base-tag>
              <h2 class="mt-5" v-if="item?.headline">
                {{ item?.headline }}
              </h2>
            </div>
            <base-image
              v-bind="item?.image.data?.attributes"
              :eager-loading="true"
              class="h-screen-lg lg:h-screen-xl w-full object-center object-cover order-1 md:order-2 md:outer-space"
              :class="
                ReferenceSliderFlipped ===
                Enum_Componentblockreferenceslider_Flipped.Left
                  ? 'col-start-1 xl:col-start-2  xl:col-end-6  md:row-start-1 pr-5 xl:pr-0'
                  : 'xl:col-start-8 xl:col-end-12'
              "
              sizes="(min-width: 768px) 50vw, (max-width: 767px) 100vw"
            />
          </div>
        </div>
      </swiper-slide>
    </swiper>
    <div class="outer-space w-full flex justify-center items-center mt-10">
      <base-button
        :type="
          ReferenceSliderVariation ===
          Enum_Componentblockreferenceslider_Variation.Dark
            ? 'primary'
            : 'secondary'
        "
        class="flex items-center justify-center whitespace-pre w-full md:w-fit"
        :to="
          parentLink
            ? parentLink +
              '/' +
              ReferenceSliderItem[swiperController?.activeIndex]?.reference
                ?.data?.attributes?.slug
            : ''
        "
        :class="{
          'border-integer-white':
            ReferenceSliderVariation ===
            Enum_Componentblockreferenceslider_Variation.Dark,
        }"
      >
        {{ t("readMore") }}
        <template #icon-right> <arrow /></template>
      </base-button>
    </div>
  </layout-block>
</template>
